import React, {
  FormEvent,
  RefObject,
  createRef,
  useCallback,
  useEffect,
  useState
} from 'react';
import { VscChevronDown } from 'react-icons/vsc';
import { voiceStore } from 'entities/voice';
import { Language, capitalize, useClickOutside } from 'shared';

const moveLanguageToTop = (languages: Language[], language: string) => {
  const index = languages.findIndex((lang) => lang.name === language);

  const newFilteredLanguages = [...languages];

  newFilteredLanguages.splice(index, 1);
  newFilteredLanguages.unshift(languages[index]);

  return newFilteredLanguages;
};

const LanguageSelect = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchString, setSearchString] = useState('');
  const { languages, filters, setFilters } = voiceStore();
  const [filteredLanguages, setFilteredLanguages] = useState(languages);

  const inputRef = createRef<HTMLInputElement>();

  const langRef = createRef<HTMLDivElement>();
  useClickOutside(langRef, setIsOpen);

  const handleSearch = (searchString: string) => {
    setSearchString(searchString);
    const filtered = languages.filter((language) =>
      language.name?.toLowerCase().startsWith(searchString?.toLowerCase())
    );

    setFilteredLanguages(filtered);
  };

  const clearSearch = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        setSearchString('');

        const newLanguages = moveLanguageToTop(
          languages,
          filters?.language.name as string
        );
        setFilteredLanguages(newLanguages);
      }
    },
    [inputRef.current]
  );

  useEffect(() => {
    if (searchString !== '') {
      document.addEventListener('keydown', clearSearch);
    } else {
      document.removeEventListener('keydown', clearSearch);
    }
  }, [searchString]);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    } else {
      setSearchString('');
      const newLanguages = moveLanguageToTop(
        languages,
        filters?.language.name as string
      );
      setFilteredLanguages(newLanguages);
    }
  }, [isOpen]);

  const handleChangeVoice = (
    e: FormEvent,
    langRef: RefObject<HTMLDivElement>
  ) => {
    e.preventDefault();
    if (filteredLanguages.length > 0) {
      handleChoose(filteredLanguages[0], langRef);

      setIsOpen(false);
    }
  };

  const handleChoose = (
    language: Language,
    langRef: RefObject<HTMLDivElement>
  ) => {
    setSearchString('');

    const newFilteredLanguages = moveLanguageToTop(languages, language.name);

    langRef.current && langRef.current.scrollTo(0, 0);

    setFilteredLanguages(newFilteredLanguages);
    setFilters({
      ...filters,
      language
    });
  };

  return (
    <div className={`yep_ex-group yep_ex-relative yep_ex-mb-2 yep_ex-w-full`}>
      <div
        className="yep_ex-text-base yep_ex-border yep_ex-border-neutral-300 yep_ex-rounded-md yep_ex-text-neutral-500 focus-visible:yep_ex-outline-none 
        disabled:yep_ex-bg-white disabled:yep_ex-opacity-50 yep_ex-text-md focus:yep_ex-outline-none yep_ex-shadow-sm focus:yep_ex-ring-primary-600 focus:yep_ex-border-primary-600 
        yep_ex-cursor-pointer yep_ex-relative yep_ex-flex yep_ex-items-center yep_ex-justify-between yep_ex-w-full yep_ex-px-4 yep_ex-py-3"
        onClick={() => setIsOpen((prev) => !prev)}
        ref={langRef}
      >
        <form
          onSubmit={(e) => handleChangeVoice(e, langRef)}
          className="yep_ex-absolute yep_ex-top-0 yep_ex-left-0 yep_ex-p-0 yep_ex-opacity-0 yep_ex-w-1 -yep_ex-z-10"
        >
          <input
            ref={inputRef}
            type="text"
            value={searchString}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </form>
        <span>{capitalize(filters?.language.name as string)}</span>
        <VscChevronDown
          className={`${isOpen && 'yep_ex-transform yep_ex-rotate-180'}`}
        />
        <div
          className={`yep_ex-absolute yep_ex-w-full yep_ex-max-h-[10rem] yep_ex-top-[3.25rem] yep_ex-py-1 yep_ex-px-1 yep_ex-left-0 yep_ex-overflow-y-scroll 
          yep_ex-bg-white yep_ex-z-50 yep_ex-border yep_ex-border-neutral-300 yep_ex-rounded-lg ${
            isOpen ? 'yep_ex-block' : 'yep_ex-hidden'
          }`}
        >
          {filteredLanguages &&
            filteredLanguages.length > 0 &&
            filteredLanguages.map((l, ind) => (
              <div
                onClick={() => handleChoose(l, langRef)}
                key={`languages-${l?.name}-${ind}`}
                className={`yep_ex-py-2 yep_ex-px-2 yep_ex-flex yep_ex-gap-2 ${
                  l?.name === filters?.language.name &&
                  'yep_ex-bg-slate-200 yep_ex-rounded-lg'
                }`}
              >
                {l?.country?.image ? (
                  <img
                    src={l.country?.image}
                    alt={l.name}
                    className="yep_ex-w-5"
                  />
                ) : (
                  l?.country?.emoji
                )}{' '}
                {capitalize(l?.name)}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default LanguageSelect;
