import React from 'react';

interface ToolTipProps {
  topClass?: string;
  leftClass?: string;
  text: string;
  className?: string;
}

export const Tooltip = ({
  topClass,
  leftClass,
  text,
  className
}: ToolTipProps) => {
  return (
    <span
      className={`yep_ex-text-white yep_ex-hidden yep_ex-absolute yep_ex-py-2 yep_ex-px-3 ${topClass} ${leftClass} yep_ex-whitespace-nowrap yep_ex-rounded-lg yep_ex-bg-[#000] yep_ex-text-xs yep_ex-z-[99999]
      group-hover:yep_ex-block ${className}`}
    >
      {text}
    </span>
  );
};
