import clsx from 'clsx';
import { avatarStore } from 'entities/avatar';
import { canvasStore } from 'entities/canvas';
import { useEffect, useState } from 'react';
import { featureFlags } from 'shared';
import { videoStore } from 'entities/video';
import { CanvasCTA } from 'features/canvas-cta';
import { Watermark } from './Watermark';

export const PlainCanvas = () => {
  const { currentAvatar, isCropped, setIsCropped } = avatarStore();
  const { currentFormat } = canvasStore();
  const backgroundColor = videoStore((state) => state.backgroundColor);

  const [img, setImg] = useState<string>('');

  useEffect(() => {
    if (currentAvatar) {
      if (!currentAvatar.menuCircleImageUrl) {
        setImg(currentAvatar.menuImageUrl ?? currentAvatar.inputImageUrl ?? '');
        setIsCropped(false);
      }
      const image = isCropped
        ? currentAvatar.menuCircleImageUrl
        : currentAvatar.menuImageUrl;
      setImg(currentAvatar.inputImageUrl ?? image ?? '');
    } else {
      setImg('');
    }
  }, [currentAvatar, isCropped]);

  return (
    <div className="yep_ex-w-full yep_ex-h-full yep_ex-flex yep_ex-items-center yep_ex-overflow-hidden yep_ex-max-h-full yep_ex-max-w-full yep_ex-relative">
      {currentAvatar ? (
        featureFlags.format ? (
          <img
            className={clsx(
              'yep_ex-mx-auto yep_ex-object-contain yep_ex-overflow-hidden yep_ex-max-w-full yep_ex-max-h-full',
              currentFormat === 0.8 ||
                (currentFormat === 0.5625 && 'yep_ex-h-full'),
              currentFormat === 1 ||
                (currentFormat === 1.77777777778 && 'yep_ex-w-full')
            )}
            src={img}
            alt="avatar"
            style={{
              backgroundColor
            }}
          />
        ) : (
          <img
            className={clsx(
              'yep_ex-mx-auto yep_ex-object-contain yep_ex-overflow-hidden yep_ex-max-w-full yep_ex-max-h-full'
            )}
            src={img}
            alt="avatar"
            style={{
              backgroundColor
            }}
          />
        )
      ) : (
        <div
          className=" yep_ex-h-full yep_ex-bg-white yep_ex-aspect-square yep_ex-mx-auto yep_ex-flex yep_ex-justify-center"
          style={{
            backgroundColor
          }}
        >
          <CanvasCTA />
        </div>
      )}
      <Watermark />
    </div>
  );
};
