import React, { useState } from 'react';
import clsx from 'clsx';
import BackgroundColorSelect from 'features/background-color/ui';
import { CropAvatar } from 'features/crop-actor';
import { featureFlags } from 'shared';
import { creditStore } from 'entities/credit';
import { appStore } from 'app/store';
import { ChatGPTButton } from 'features/chat-gpt';

interface CanvasControlsProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  tabs: string[];
}

export const CanvasControls = ({
  activeTab,
  setActiveTab,
  tabs
}: CanvasControlsProps) => {
  return (
    <div className="yep_ex-border-b yep_ex-border-neutral-200 yep_ex-px-6 yep_ex-py-[1.125rem] yep_ex-justify-between yep_ex-flex">
      <div className="yep_ex-flex yep_ex-items-center yep_ex-space-x-8">
        {tabs.map((tab) => (
          <button
            className={clsx(
              'yep_ex-border yep_ex-border-transparent yep_ex-text-sm',
              activeTab === tab
                ? 'yep_ex-border-b-primary-600 yep_ex-text-neutral-800 yep_ex-font-semibold'
                : 'yep_ex-border-b-transparent yep_ex-text-neutral-500 hover:yep_ex-text-neutral-800'
            )}
            onClick={() => setActiveTab(tab)}
            disabled={activeTab === tab}
            key={`tab-${tab}`}
          >
            {tab}
          </button>
        ))}
      </div>
      <div className="yep_ex-divide-x-2 yep_ex-flex yep_ex-gap-4 yep_ex-items-center">
        {featureFlags.backgroundColor && <BackgroundColorSelect />}
        {featureFlags.cropActor && <CropAvatar />}
        {featureFlags.chatgpt && (
          <div className="yep_ex-block lg:yep_ex-hidden">
            <ChatGPTButton />
          </div>
        )}
      </div>
    </div>
  );
};
