import { useState } from 'react';
import { AiOutlinePauseCircle, AiOutlinePlayCircle } from 'react-icons/ai';
import { videoStore } from 'entities/video';
import { voiceStore } from 'entities/voice';
import { usePlayAudio } from 'features/play-audio';
import { getEstimatedCredits, textToSpeechApi } from 'features/text-to-speech';
import {
  Loader,
  Modal,
  OutlineButton,
  PrimaryButton,
  VoicePreviewPayload,
  firebaseAnalytics,
  getErrorMessage,
  showError,
  Tooltip
} from 'shared';
import { creditStore } from 'entities/credit';
import { logEvent } from 'firebase/analytics';
import * as amplitude from '@amplitude/analytics-browser';
import SingleCheckBox from 'shared/ui/SingleCheckBox';
import store from 'store';
import { appStore } from 'app/store';
import { FaPlay } from 'react-icons/fa';

type ScriptPreviewData = {
  script: string;
  voiceId: string;
};

export const ListenScript = () => {
  const { user, toggleCreateAccModal } = appStore();
  const { planFeatures, toggleFeatureBlockModal } = creditStore();
  const { scriptText } = videoStore();
  const { currentVoice } = voiceStore();
  const [preview, setPreview] = useState<ScriptPreviewData>({
    script: '',
    voiceId: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [openConfirmPreviewModal, setOpenConfirmPreviewModal] = useState(false);
  const [currentAudioPreview, setCurrentAudioPreview] =
    useState<HTMLAudioElement | null>(null);
  const [estimatedCredits, setEstimatedCredits] = useState(0);
  const { isPlaying, handlePlayAudio, handlePauseAudio, handleSeekTime } =
    usePlayAudio();
  const fetchCredits = creditStore((state) => state.fetchCredits);
  const [skipEstimateModal, setSkipEstimateModal] = useState<boolean>(
    store.get('skipSpeechModal', false)
  );

  const handleSkipModal = () => {
    store.set('skipSpeechModal', !skipEstimateModal);
    setSkipEstimateModal((state) => !state);
  };

  const handleCheckScript = async () => {
    if (!user) return toggleCreateAccModal();
    if (!planFeatures?.speechAllowed) {
      toggleFeatureBlockModal('speech');
      return;
    }
    if (isPlaying) {
      handleSeekTime(0);
      handlePauseAudio();
      return;
    }

    if (!scriptText.trim())
      return showError(
        'Empty script text. Please provide a valid script before submitting.'
      );

    if (
      currentAudioPreview &&
      currentVoice &&
      preview.script === scriptText &&
      preview.voiceId === currentVoice.voiceId
    ) {
      handlePlayAudio(currentAudioPreview, true);
      return;
    }

    if (!currentVoice) return;

    if (skipEstimateModal) return handlePlayScript();

    const payload: VoicePreviewPayload = {
      script: scriptText,
      voiceId: currentVoice.voiceId ? (currentVoice.voiceId as string) : '',
      soundTemplate: '',
      speechVolume: 1,
      speechSpeed: 1,
      estimate: true
    };

    setIsLoading(true);

    try {
      const credits = await getEstimatedCredits(payload);
      setEstimatedCredits(credits);
      setOpenConfirmPreviewModal(true);
    } catch (error) {
      const message = getErrorMessage(
        error,
        'There was a problem when we tried to estimate cost of speech preview'
      );
      showError(message);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePlayScript = async () => {
    setOpenConfirmPreviewModal(false);
    setIsLoading(true);
    setCurrentAudioPreview(null);
    if (!currentVoice) return;
    try {
      const payload: VoicePreviewPayload = {
        script: scriptText,
        voiceId: currentVoice.voiceId ? (currentVoice.voiceId as string) : '',
        soundTemplate: '',
        speechVolume: 1,
        speechSpeed: 1,
        estimate: false
      };
      const { data } = await textToSpeechApi(payload);

      const audioUrl = data.url;

      setPreview({
        script: scriptText,
        voiceId: currentVoice ? currentVoice.voiceId : ''
      });

      const newAudio = new Audio(audioUrl);
      handlePlayAudio(newAudio, true);
      setCurrentAudioPreview(newAudio);
      amplitude.track('express_preview_voice');
      logEvent(firebaseAnalytics, 'express_preview_voice');
    } catch (error) {
      const message = getErrorMessage(
        error,
        `We couldn't play script. Please try again later.`
      );
      showError(message);
    } finally {
      fetchCredits();
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="yep_ex-group yep_ex-relative">
        <button
          onClick={() => handleCheckScript()}
          disabled={!scriptText.trim() || isLoading}
          className="yep_ex-border yep_ex-border-neutral-300 disabled:yep_ex-border-neutral-200 yep_ex-text-neutral-700 disabled:yep_ex-text-neutral-300 yep_ex-rounded-md yep_ex-px-3.5 yep_ex-py-2"
        >
          <div className="yep_ex-flex yep_ex-gap-2 yep_ex-font-semibold yep_ex-items-center">
            {isLoading ? (
              <Loader isButton size={24} />
            ) : (
              <>
                {isPlaying ? (
                  <AiOutlinePauseCircle className="yep_ex-text-2xl" />
                ) : (
                  <>
                    <span>Listen</span>
                    <FaPlay size={20} />
                  </>
                )}
              </>
            )}
          </div>
        </button>
        <Tooltip
          text={'Enter a script to preview it'}
          topClass="-yep_ex-bottom-10"
          leftClass="yep_ex-left-1/2 -yep_ex-translate-x-1/2"
        />
      </div>
      <Modal
        open={openConfirmPreviewModal}
        onClose={setOpenConfirmPreviewModal}
        widthClasses="yep_ex-rounded-lg yep_ex-w-96 sm:yep_ex-my-8"
        noPadding
      >
        {isLoading ? (
          <div className="yep_ex-flex yep_ex-justify-center yep_ex-p-6">
            <Loader isButton size={24} />
          </div>
        ) : (
          <>
            <div className="yep_ex-p-6 yep_ex-pb-4">
              <div className="yep_ex-text-lg yep_ex-font-semibold yep_ex-mb-2">
                Listen script
              </div>

              <p className=" yep_ex-text-neutral-600 yep_ex-text-sm yep_ex-font-normal yep_ex-mb-4">
                The preview of this script with your selected voice will cost{' '}
                {estimatedCredits.toFixed(2)} credit
                {estimatedCredits > 1 ? 's' : ''}.
              </p>
              <div className="flex py-0.5 gap-3 items-center">
                <SingleCheckBox
                  label="Don’t show me this again."
                  onClick={handleSkipModal}
                  value={skipEstimateModal}
                  className="text-neutral-700 font-medium leading-normal"
                />
              </div>
            </div>
            <div className="yep_ex-border-b yep_ex-border-px yep_ex-border-neutral-200" />
            <div className="yep_ex-flex yep_ex-justify-between yep_ex-p-6">
              <OutlineButton
                onClick={() => setOpenConfirmPreviewModal(false)}
                className=" yep_ex-w-40"
              >
                Cancel
              </OutlineButton>
              <PrimaryButton
                className="yep_ex-w-40"
                onClick={() => handlePlayScript()}
              >
                Accept
              </PrimaryButton>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};
