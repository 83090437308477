import { useRef, useState, useEffect } from 'react';
import TabsGroup from '../TabsGroup';
import { Avatars } from 'widgets/Avatars';
import { Voices } from 'widgets/Voices';
import { Style } from 'widgets/Style';
import { Developer } from 'widgets/Developers';
import { NameT, tabs } from 'features/navigation';
import {
  DEFAULT_FEMALE_VOICE,
  DEFAULT_MALE_VOICE,
  IAvatar
} from '../../../../shared';
import { avatarStore } from 'entities/avatar';
import { voiceStore } from 'entities/voice';

export const Sidebar = () => {
  const [activeTab, setActiveTab] = useState<NameT>(tabs[0].name);

  const sideBarRef = useRef(null);
  const { currentAvatar } = avatarStore();
  const { setDefaultVoice, currentVoice } = voiceStore();

  const handleChangeTab = (tab: string) => {
    setActiveTab(tab);
  };

  const prevAvatarRef = useRef<IAvatar | null>(currentAvatar);

  useEffect(() => {
    const prevAvatar = prevAvatarRef.current;

    if (currentAvatar) {
      if (
        !prevAvatarRef ||
        prevAvatar?.gender !== currentAvatar.gender ||
        currentAvatar.gender?.toLowerCase() !==
          currentVoice?.gender?.toLowerCase()
      ) {
        const genderVoice: string =
          currentAvatar.gender === 'Male'
            ? DEFAULT_MALE_VOICE
            : DEFAULT_FEMALE_VOICE;
        setDefaultVoice(genderVoice);
      }
      prevAvatarRef.current = currentAvatar;
    }
  }, [currentAvatar]);

  return (
    <div
      className={`yep_ex-flex md:yep_ex-w-auto yep_ex-transition-transform md:yep_ex-translate-x-0 yep_ex-fixed md:yep_ex-sticky yep_ex-top-0 yep_ex-right-0 yep_ex-z-10 yep_ex-h-full yep_ex-translate-x-full`}
    >
      <div
        className={`yep_ex-sidebar yep_ex-flex yep_ex-flex-col yep_ex-w-[16.75rem] xl:yep_ex-w-96 yep_ex-pb-4 md:yep_ex-pt-2 yep_ex-transition-transform yep_ex-bg-white -yep_ex-z-10 sm:yep_ex-border-l yep_ex-border-solid border-neutral-200`}
        ref={sideBarRef}
      >
        {activeTab === 'avatar' && <Avatars />}
        {activeTab === 'voice' && <Voices />}
        {activeTab === 'style' && <Style />}
        {activeTab === 'developers' && <Developer />}
      </div>
      <TabsGroup
        tabs={tabs}
        activeTab={activeTab}
        toggleTab={handleChangeTab}
      />
    </div>
  );
};
