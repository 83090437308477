import { create } from 'zustand';
import { creditApi } from '../api';
import { ICurrentPlanFeatures } from './creditTypes';

type CreditState = {
  credits: number;
  maxCredits: number;
  creditsError: string | null;
  planFeatures: ICurrentPlanFeatures | undefined;
  isFree: boolean;
};

type CreditActions = {
  fetchCredits: () => void;
  updateCreditStore: (key: string, value: any) => void;
  toggleBuyCreditsModal: () => void;
  toggleUpgradeModal: () => void;
  toggleFeatureBlockModal: (open: boolean | typeof features[number]) => void;
  setEstimatedVideoPrice: (estimatedPrice: number | null) => void;
};

export const features = [
  'express',
  'pro',
  'agents',
  'extraCredits',
  'api',
  'premiumVoices',
  'speech',
  'chatGpt',
  'createAvatar'
] as const;

export type CreditStore = CreditState & CreditActions;

export const creditStore = create<CreditStore>()((set) => ({
  credits: 0,
  maxCredits: 0,
  creditsError: null,
  estimatedVideoPrice: null,
  planFeatures: undefined,
  isFree: false,
  toggleUpgradeModal: () => {},
  toggleBuyCreditsModal: () => {},
  toggleFeatureBlockModal: () => {},
  setEstimatedVideoPrice: (estimatedPrice) => {},
  updateCreditStore: (key, value) => {
    set({ [key]: value });
  },
  fetchCredits: async () => {
    try {
      const { data } = await creditApi.fetchCredits();
      if (data.balance) {
        set({ credits: data.balance });
      }
      return;
    } catch (e) {
      console.error(e);
      set({
        creditsError: `Error fetching credits ${(e as Error).message}`,
        credits: 0,
        maxCredits: 0
      });
    }
  }
}));
