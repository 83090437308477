import React from 'react';
import { ReactComponent as LogoWatermark } from './img/logoWatermark.svg';
import { creditStore } from 'entities/credit';
import { appStore } from 'app/store';

export const Watermark = () => {
  const { toggleUpgradeModal, isFree } = creditStore((state) => ({
    toggleUpgradeModal: state.toggleUpgradeModal,
    isFree: state.isFree
  }));
  if (!isFree) return null;

  const { user, toggleCreateAccModal } = appStore((state) => ({
    user: state.user,
    toggleCreateAccModal: state.toggleCreateAccModal
  }));

  const handleClick = () => {
    if (user) {
      toggleUpgradeModal();
    } else {
      toggleCreateAccModal();
    }
  };

  return (
    <div
      onClick={handleClick}
      className={'absolute bottom-4 right-4 cursor-pointer'}
    >
      <LogoWatermark />
    </div>
  );
};
